import React, { useEffect, useState,useRef } from "react";
import { useSearchParams } from "react-router-dom";

import Table from "../../../components/Table";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { getData } from "../../../services/HttpServices";
import { setPageTitle,setBreadcrumbs  } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';

const ProductList = () => {
    const searchRef = useRef("");
    const dispatch = useDispatch();

    const [productData,setProductData] = useState([])
    const [productCounts,setProductCounts] = useState(0)
    const [pageNo,setPageNo] = useState(1)
    const [isNext,setIsNext] = useState(false)
    const [limit,setLimit] = useState(10)
    const [search,setSearchData] = useState('');
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    
    let tablColumns = {
        id:'ID',
      //  ean_no:'EAN NO',
        description_1:'Description',
        supplier:'Supplier',
        gtin_no:'GTIN NO',
        size:'Size',
        quantity:'Quantity',
        unit_price:'Unit Price',
    }
    const getProductData = (pageNo, query='') =>{
        const orderby = searchParams.get("orderby")
        let url = '/purchaseproducts/list?page='+pageNo+'&query='+query
        if(orderby){
            url += '&orderby='+orderby
        }
        getData(url).then((response)=>{
            if(response.status === 200){
                let responseData = response.data
                setProductData(responseData.data.products)
                setProductCounts(responseData.data.counts)
                setPageNo(responseData.data.page_no)
                setIsNext(responseData.data.is_next)
                setLimit(responseData.data.limit)
            }
        })
    }
    const paginationUpdate = (pageno) => {
        setPageNo(pageno)
        getProductData(pageno,search)
    }
    useEffect(()=>{
        
    getProductData(pageNo)
    dispatch(setPageTitle('Purchase Products'))
    dispatch(setBreadcrumbs([
        {
            label: 'Dashboard',
            link: '/dashboard',
            active: false, // true,false
            state: '/purchaseproducts',
        },{
            label: 'Purchase Products',
            link: '/purchaseproducts',
            active: true, // true,false
            state: '/purchaseproducts',
        }
      ]))
     // eslint-disable-next-line
    },[dispatch])
    const onSearchChange = (data) => {
        setSearchData(data)
     };
      const submitFilter = () => {
          getProductData(1,search)
      }
      const clearFilter = () => {
        searchRef.current.value = ''
        getProductData(1)
        setSearchData("")
    }
    return(
        <DefaultLayout>
        <section className="section">
            <div className="row">
                <div className="card">
                    <div className="card-body mt-5">
                        <Table data={{'list':productData, 'column': tablColumns, counts:productCounts, pageno: pageNo,limit:limit, isNext:isNext, 'editlink': '/purchaseproducts/' }} updateFn = {paginationUpdate} submitFilter={submitFilter} clearfilter={clearFilter} 
                        filters={[         
                            {
                                label:"Find by Code,Description",
                                type:'search',
                                class:'form-control',
                                searchFilterFn:onSearchChange,
                                default:search,
                                ref:searchRef,
                            }
                        ]}
                        submenu={[
                                        {
                                            label:"Add New",
                                            link:'/purchaseproducts/add',
                                            buttonClass:'primary float-end'

                                        }
                                    ]}/> 
                        </div>
                        </div>
                        </div>
           </section>
        </DefaultLayout>
    )
}
export default ProductList;