import axios from 'axios';
import { decrypt } from '../helpers/security';
/*Get Request*/
const URL = process.env.REACT_APP_API_URL
//const URL = '';
const BASIC_AUTH_USER = process.env.REACT_APP_BASIC_AUTH_USER
const BASIC_AUTH_PASSWORD = process.env.REACT_APP_BASIC_AUTH_PASSWORD
    export const getData = async (action) => {
      
        try{
            const token = localStorage.getItem('cmw-user-token');
            const response = await axios.get(URL+action,{
                headers: {
                  'Content-Type': 'application/json',
                  'token':decrypt(token)
                }, 
                auth: {
                  username: BASIC_AUTH_USER,
                  password: BASIC_AUTH_PASSWORD
                }
              });
            return response;
        }catch(error) {
            console.log('get data error==' + error)
            return [];
        }
        
    }

/*Post Request*/
export const postData = async (action,data) => {
        try{
            const token = localStorage.getItem('cmw-user-token');
            const response = await axios.post(URL+action,data,{
                headers: {
                  'Content-Type': 'application/json',
                  'token':decrypt(token)
                }, 
                auth: {
                  username: BASIC_AUTH_USER,
                  password: BASIC_AUTH_PASSWORD
                }
              });
            return response;
        }catch(error) {
            console.log('post data error==' + error)
            return [];
        }
        
    }
/*Put Request*/
export const putData = async (action,data) => {
        try{
            const token = localStorage.getItem('cmw-user-token');

            const response = await axios.put(URL+action,data,{
                headers: {
                  'Content-Type': 'application/json',
                  'token':decrypt(token)
                }, 
                auth: {
                  username: BASIC_AUTH_USER,
                  password: BASIC_AUTH_PASSWORD
                }
              });
            return response;
        }catch(error) {
            return [];
        }
        
}
/*Post Multipart Request*/
export const postMultiPartData = async (action,data) => {
  try{
      const token = localStorage.getItem('cmw-user-token');
      const response = await axios.post(URL+action,data,{
          headers: {
            'Content-Type': 'multipart/form-data',
            'token':decrypt(token)
          }, 
          auth: {
            username: BASIC_AUTH_USER,
            password: BASIC_AUTH_PASSWORD
          }
        });
      return response;
  }catch(error) {
      console.log('post data error==' + error)
      return [];
  }
  
}
/*Delete Request*/
export const deleteData = async (action) => {
        try{
            const token = localStorage.getItem('cmw-user-token');
            const response = await axios.delete(URL+action,{
                headers: {
                  'Content-Type': 'application/json',
                  'token':decrypt(token)
                }, 
                auth: {
                  username: BASIC_AUTH_USER,
                  password: BASIC_AUTH_PASSWORD
                }
              });
            return response;
        }catch(error) {
            return [];
        }
        
}

/*Export files*/
export const exportFile = async (action,filename) => {
  const token = localStorage.getItem('cmw-user-token');
  axios.post(URL+action, null,
    {
        headers:
        {
            'Content-Disposition': "attachment; filename="+filename,
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'token':decrypt(token)
        },
        responseType: 'arraybuffer',
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
    }).catch((error) => console.log(error));

}
