import React, { useState, useEffect } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom"
import { getData, postData } from "../../../services/HttpServices";
import { PrimaryButton, DeleteButton } from "../../../components/Button";
import { AlertError, AlertSuccess } from "../../../components/Alerts";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { setPageTitle,setBreadcrumbs } from '../../../store/resourceSlice';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
const CreatePurchaseOrder = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()


    const [supplierId, setSupplierId] = useState(0);
    const [deliveryDate, setDeliveryDate] = useState("");
    const [supplierData, setSupplierData] = useState([]);
    const { register,handleSubmit,formState: { errors } } = useForm();

    const [productRows, setProductRows] = useState([{ product_id: 0,unit_quantity: 0 }]);
    const [productData, setProductData] = useState([]);
    const [supplierInfo, setSupplierInfo] = useState(null);
    const [settingData, setSettingData] = useState(null);


    const onSubmit = (data) => {
        if (!supplierId) {
            AlertError("Please choose a supplier");
            return;
        }
        if (!deliveryDate) {
            AlertError("Please select a delivery date");
            return;
        }
        if (productRows.length === 0) {
            AlertError("Please add a product.");
            return;
        }
        let errors = productRows.filter((res) => {
            return res.product_id === 0 || res.unit_quantity === "" || res.price === 0;
        });
        if (errors.length > 0) {
            AlertError("The product section required attention. All fields are required.");
            return;
        }
        let newDeliveryDate = deliveryDate.toLocaleDateString("en-UK");
        data.deliverydate=newDeliveryDate
        data.productRows = productRows
        data.supplier = supplierId;
      
        postData('/purchase/order', data).then((response) => {
            let resposnseData = response.data
            if (resposnseData.success === false) {
                AlertError(resposnseData.message);
                return
            }
            AlertSuccess(resposnseData.message)
            navigate("/purchaseorders");
        }).catch(e => {
            AlertError(e);
        })
    };

    const addProduct = () => {
        let newAdded = [...productRows, { product_id: 0, unit_quantity: 0,quantity:0,size:0, price: 0}];
        setProductRows(newAdded);

    };
    const removeProduct = (index) => {
        let newRows = productRows.filter((arrow, key) => key !== index);
        setProductRows(newRows);
    };

    const updateProductRow = (res, index) => {
        let temp = [...productRows];
        productRows[index]["product_id"] = (res) ? res.value : 0
        productRows[index]["size"] = (res) ? res.size : 1
        setProductRows(temp)
    }
    const supplierChangeHandler = (res) => {

        if (res) {
          setSupplierInfo(res)
            setSupplierId(res.value)
            getProductData(res.value)
        } else {
          setSupplierInfo(null)
          setSupplierId(0);
          setProductData([])
        }
    }
    const updateTotalQuanity = (value, index, type) => {
      let temp = [...productRows];
      if (type === 'unit'){
        temp[index].unit_quantity =  value;
        temp[index].total_quantiy = temp[index].size * value;
      }
      setProductRows(temp)
  }
    /*Get Products Data*/
    const getProductData = (supplierId) => {
      getData("/purchaseproducts/filter/"+supplierId).then((response) => {
          if (response.status === 200) {
              let responseData = response.data;
              if (responseData.success === false) {
                AlertError(responseData.message);
                return
              }
              setProductData(responseData.data);
          }
      });
  };
  


    useEffect(() => {
        const getSuppliers = () => {
            getData("/suppliers/filter").then((response) => {
                if (response.status === 200) {
                    let responseData = response.data;
                    if (responseData.success) {
                        setSupplierData(responseData.data);
                    }
                }
            });
        };
        getSuppliers();
        
      const getSettingData = () => {
        getData('/settings').then((response) => {
            if (response.status === 200) {
                let responseData = response.data
                if (responseData.success) {
                    setSettingData(responseData.data)

                }
            }
        })
     }
      getSettingData()
        dispatch(setPageTitle('Create Purchase Order'))
        dispatch(setBreadcrumbs([
          {
              label: 'Dashboard',
              link: '/dashboard',
              active: false, // true,false
              state: '/purchaseorders/add',
          },{
              label: 'Purchase Orders',
              link: '/purchaseorders',
              active: false, // true,false
              state: '/purchaseorders/add',
          },{
            label: 'Create Purchase Order',
            link: '/purchaseorders/add',
            active: true, // true,false
            state: '/purchaseorders/add',
        }
        ]))
    }, [dispatch]);
    return (
        <DefaultLayout >
      <section className="section">
        <div className="row">
          <div className="card">
                     <div className="card-body">
                    <div className="container py-5">
                    <div className="row d-flex justify-content-center align-items-center h-100">
                    <div className="col-lg-12 col-xl-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                          <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="supplier" className="col-12 col-form-label">Supplier<span className="text-danger">*</span></label>
                            <div className="col-12">
                                    <Select
                                        options={supplierData}
                                        defaultValue={supplierId}
                                        onChange={supplierChangeHandler}
                                        isSearchable={true}
                                        id="supplier"
                                        isClearable={true}
                                        placeholder='Select a supplier'
                                      />
                                      <small></small>
                                    </div>
                                   
                         </div>
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="deliverydate" className="col-12 col-form-label">Delivery Date<span className="text-danger">*</span></label>
                            <div className="col-12">
                            <DatePicker
                                    className="form-control"
                                    selected={deliveryDate}
                                    id="deliverydate"
                                    onChange={(date) => setDeliveryDate(date)}
                                    dateFormat="dd-MM-yyyy"
                                    placeholderText="Click to select a date"
                                    monthsShown={1}
                                    minDate={new Date()}
                                    isClearable={deliveryDate}
                                  />
                              </div>
                                   
                         </div>
                         {supplierInfo && <div className="col-lg-6 col-xl-6 mb-3">
                            <div className="col-12">
                            <div class="card" style={{width: '8rem;'}}>
                            <div class="card-body">
                              <h5 class="card-title">{supplierInfo.contact_name || '-'}</h5>
                              <p class="card-text"><b>Email:</b> {supplierInfo.email || '-'}</p>
                              <p class="card-text"><b>Phone:</b> {supplierInfo.contact_number || '-'}</p>
                              <p class="card-text"><b>Address:</b> {supplierInfo.address_1 || '-'}</p>
                              <p class="card-text"><b>City:</b> {supplierInfo.city || '-'}</p>
                              <p class="card-text"><b>County:</b> {supplierInfo.county || '-'}</p>
                              <p class="card-text"><b>Postcode:</b> {supplierInfo.postcode || '-'}</p>
                            </div>
                          </div>
                            </div>     
                         </div>
                        }
                        {supplierInfo && settingData && <div className="col-lg-6 col-xl-6 mb-3">
                            <div className="col-12">
                            <div class="card" style={{width: '8rem;'}}>
                            <div class="card-body">
                              <h5 class="card-title">{settingData.name || '-'}</h5>
                              <p class="card-text"><b>Email:</b> {settingData.email || '-'}</p>
                              <p class="card-text"><b>Address:</b> {settingData.address || '-'}</p>
                              <p class="card-text"><b>Phone:</b> {settingData.phone || '-'}</p>
                            </div>
                          </div>
                            </div>     
                         </div>
                        }
              <hr />
              <div className="col-12 mb-3">
                <h6 className="card-title">Delivery Address<span className="text-danger">*</span></h6>
              </div>
              <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="delivery_address_1" className="col-12 col-form-label">Address 1<span className="text-danger">*</span></label>
                            <div className="col-12">
                            <input type="text" className="form-control" id="delivery_address_1" defaultValue={""} {...register("delivery_address_1", {required: true , maxLength: 125})} />
                              {errors?.delivery_address_1 && errors?.delivery_address_1.type === "required" && <span className="error">Please enter delivery address</span>}
                              {errors?.delivery_address_1 && errors?.delivery_address_1.type === "maxLength" && <span className="error">Max length exceeded</span> }
                              </div>
                                   
                         </div>   
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="delivery_address_2" className="col-12 col-form-label">Address 2</label>
                            <div className="col-12">
                            <input type="text" className="form-control" id="delivery_address_2" defaultValue={""} {...register("delivery_address_2", {required: false , maxLength: 125})} />
                              {errors?.delivery_address_2 && errors?.delivery_address_2.type === "maxLength" && <span className="error">Max length exceeded</span> }
                              </div>
                                   
                         </div> 
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="delivery_address_3" className="col-12 col-form-label">Address 3</label>
                            <div className="col-12">
                            <input type="text" className="form-control" id="delivery_address_3" defaultValue={""} {...register("delivery_address_3", {required: false , maxLength: 125})} />
                            
                              {errors?.delivery_address_3 && errors?.delivery_address_3.type === "maxLength" && <span className="error">Max length exceeded</span> }
                              </div>
                                   
                         </div>
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="delivery_address_4" className="col-12 col-form-label">Address 4</label>
                            <div className="col-12">
                            <input type="text" className="form-control" id="delivery_address_4" defaultValue={""} {...register("delivery_address_4", {required: false , maxLength: 125})} />
                              {errors?.delivery_address_4 && errors?.delivery_address_4.type === "maxLength" && <span className="error">Max length exceeded</span> }
                              </div>
                                   
                         </div>   
                         <div className="col-lg-6 col-xl-6 mb-3">
                          <label htmlFor="delivery_post_code" className="col-12 col-form-label">Post Code<span className="text-danger">*</span></label>
                            <div className="col-12">
                            <input type="text" className="form-control" id="delivery_post_code" defaultValue={""} {...register("delivery_post_code", {required: true , maxLength: 20})} />
                            {errors?.delivery_post_code && errors?.delivery_post_code.type === "required" && <span className="error">Please enter delivery post code</span>}
                              {errors?.delivery_post_code && errors?.delivery_post_code.type === "maxLength" && <span className="error">Max length exceeded</span> }
                              </div>
                                   
                         </div>             
              <hr />
              {productRows.length > 0 &&
                productRows.map((product, index) => (
                  <div className="row g-3 mb-3" key={index}>
                    <div className="col-3 col-md-3">
                      <label className="col-form-label">Product<span className="text-danger">*</span></label>
                      <Select
                        options={productData}
                        defaultValue={product.product_id}
                        className="col-12"
                        isSearchable={true}
                        isClearable={true}
                        onChange={(res)=>updateProductRow(res, index)}
                      />
                    </div>
                    <div className="col-3 col-md-3">
                      <label className="col-form-label">Unit Quantity<span className="text-danger">*</span></label>
                      <input
                        type="number"
                        className="form-control"
                        min={1}
                        defaultValue={product.quantity}
                        onInput={(res) => {
                          updateTotalQuanity(res.target.value,index,'unit')
                        }}
                      />
                      <input type="hidden" id="size" defaultValue={product.size} />
                      <small className="text-primary">Total Quantity : { productRows[index].total_quantiy }</small>
                    </div>
                    <div className="col-3 col-md-3">
                      <label className="col-form-label">Price<span className="text-danger">*</span></label>
                      <input
                        type="number"
                        className="form-control"
                        step={1.00}
                        defaultValue={product.price}
                        pattern="[/^\d*\.?\d*$/]"
                        onChange={(res) => {
                          productRows[index]["price"] = res.target.value;
                        }}
                      />
                    </div>
                    { productRows.length > 1 && 
                    <div className="col-3 col-md-3">
                      <div className="mt-5">
                        <DeleteButton
                          iclass="trash-fill"
                          buttonClass="danger"
                          clickfun={() => {if(window.confirm('Delete the item?')){ removeProduct(index) };}} 
                        />
                      </div>
                    </div>
                    }
                  </div>
                ))}
              <div className="row g-3 mb-3">
                <div className="col-6 col-md-3">
                  <PrimaryButton
                    title="Add Product"
                    clickfun={addProduct}
                    iclass="plus"
                    buttonClass='primary'
                  />
                </div>
              </div>
              <div className="text-center">
                <button
                  type="submit"
                
                  className="btn btn-primary sub-btn"
                >
                  Submit
                </button>
              </div>
             
            </div>
            </form>
          </div>
        </div>
                 </div>
            </div>
        </div>
        </div>
      </section>
    </DefaultLayout>
    );
};
export default CreatePurchaseOrder;