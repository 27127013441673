import React, { useEffect,useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import { postData,getData } from "../../../services/HttpServices";
import { AlertError, AlertSuccess } from "../../../components/Alerts";
import { useForm } from "react-hook-form";
import { setPageTitle,setBreadcrumbs } from "../../../store/resourceSlice";
import { useDispatch } from "react-redux";
const AddProduct = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState([])
  const [supplierData, setSupplierData] = useState([])
  /*Add Product Data*/
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const onSubmit = (data) => {
    postData("/purchaseproducts/add", data)
      .then((response) => {
        let resposnseData = response.data;
        if (resposnseData.success === false) {
          AlertError(resposnseData.message);
          return;
        }
        AlertSuccess(resposnseData.message);
        navigate("/purchaseproducts");
      })
      .catch((e) => {
        AlertError(e);
      });
  };

  useEffect(() => {
    
    getData("/category/filter").then((response) => {
      if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
            setCategoryData(responseData.data);
          }
      }
    });
    getData("/suppliers/filter").then((response) => {
      if (response.status === 200) {
          let responseData = response.data;
          if (responseData.success) {
             setSupplierData(responseData.data);
          }
      }
    });
    dispatch(setPageTitle("Add New Product"));
    dispatch(setBreadcrumbs([
      {
          label: 'Dashboard',
          link: '/dashboard',
          active: false, // true,false
          state: '/purchaseproducts/add',
      },{
          label: 'Purchase Products',
          link: '/purchaseproducts',
          active: false, // true,false
          state: '/purchaseproducts/add',
      },{
        label: 'Add New Product',
        link: '/purchaseproducts/add',
        active: true, // true,false
        state: '/purchaseproducts/add',
    }
    ]))
  }, [dispatch]);
  console.log(errors)
  return (
    <DefaultLayout>
      <section className="section">
        <div className="row">
          <div className="card">
            <div className="card-body">
              <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col-lg-12 col-xl-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                            <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="ean_no" className="col-sm-12 col-form-label">Code<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="code" defaultValue={""} {...register("code", {required: true , maxLength: 20})} />
                                        {errors?.code && errors?.code.type === "required" && <span className="error">Please enter ean no</span>}
                                        {errors?.code && errors?.code.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="postcode" className="col-sm-12 col-form-label">Category</label>
                                    <div className="col-sm-12">
                                    
                                       <select id="category" className="form-control" defaultValue={0} {...register("category", {required: false })}>
                                       <option key={0} value={0}>Select a category</option>
                                            {categoryData.length && categoryData.map((category, index) => (
                                            <option key={index} value={category.value}>{category.label}</option>
                                        ))}
                                        </select>
                                        {errors?.category && <span className="error">Please select a category</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="postcode" className="col-sm-12 col-form-label">Supplier<span className="text-danger">*</span>
                                    </label>
                                    <div className="col-sm-12">
                                    
                                       <select id="supplier" className="form-control" defaultValue={""} {...register("supplier", {required: true })}>
                                       <option key={0} value={""}>Select a supplier</option>
                                            {supplierData.length && supplierData.map((supplier, index) => (
                                            <option key={index} value={supplier.value}>{supplier.label}</option>
                                        ))}
                                        </select>
                                        {errors?.supplier && <span className="error">Please select a supplier</span>}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xl-6 mb-3">
                                    <label htmlFor="gtin_no" className="col-sm-12 col-form-label">GTIN No<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="gtin_no" defaultValue={""} {...register("gtin_no", {required: true , maxLength: 13})} />
                                        {errors?.gtin_no && errors?.gtin_no.type === "required" && <span className="error">Please enter gtin_no</span>}
                                        {errors?.gtin_no && errors?.gtin_no.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                
                                <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="description_1" className="col-sm-12 col-form-label">Description<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="description_1" defaultValue={""}  {...register("description_1", {required: true , maxLength: 125})} />
                                        {errors?.description_1 && errors?.description_1.type === "required" && <span className="error">Please enter description</span>}
                                        {errors?.description_1 && errors?.description_1.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div>
                                {/* <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="description_2" className="col-sm-12 col-form-label">Description 2<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="text" className="form-control" id="description_2" defaultValue={""}  {...register("description_2", {required: false , maxLength: 125})} />
                                        {errors?.description_2 && errors?.description_2.type === "required" && <span className="error">Please enter description</span>}
                                        {errors?.description_2 && errors?.description_2.type === "maxLength" && <span className="error">Max length exceeded</span> }
                                    </div>
                                   
                                </div> */}
                                <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="size" className="col-sm-12 col-form-label">Size/Pallet<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="number" className="form-control" id="size" defaultValue={""}  {...register("size", {required: true})}/>
                                        {errors?.size && errors?.size.type === "required" && <span className="error">Please enter size</span>}
                                    </div>
                                   
                                </div>   
                                <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="quantity" className="col-sm-12 col-form-label">Quantity<span className="text-danger">*</span></label>
                                    <div className="col-sm-12">
                                        <input type="number" className="form-control" id="quantity" defaultValue={""}  {...register("quantity", {required: true})}/>
                                        {errors?.quantity && errors?.quantity.type === "required" && <span className="error">Please enter quantity</span>}
                                    </div>
                                   
                                </div> 
                                {/* <div className="col-lg-6 col-xl-6  mb-3">
                                    <label htmlFor="unit_price" className="col-sm-12 col-form-label">Unit Price</label>
                                    <div className="col-sm-12">
                                        <input type="number" className="form-control" step={0.01} id="unit_price" defaultValue={""} {...register("unit_price", {required: false, maxLength: 30})}/>
                                        {errors?.unit_price && errors?.unit_price.type === "required" && <span className="error">Please enter unit price</span>}
                                    </div>
                                </div>  */}
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary sub-btn">Submit</button>
                                </div>
                                </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </DefaultLayout>
  );
};
export default AddProduct;
