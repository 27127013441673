import React, { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import { useNavigate } from "react-router-dom";
import { postData, getData } from "../../../services/HttpServices";
import { AlertError, AlertSuccess } from "../../../components/Alerts";
import { useForm } from "react-hook-form";
import { setPageTitle,setBreadcrumbs } from "../../../store/resourceSlice";
import { useDispatch } from "react-redux";
import Select from "react-select";
import SelectOptions from "../../../components/SelectOptions";
import { PrimaryButton, DeleteButton } from "../../../components/Button";

const AddSaleProduct = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [productRows, setProductsRows] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientid, setClientId] = useState();
  const [warehouses, setWarehouses] = useState([]);
  const [wareObj, setWareObj] = useState({});
  //const [vatCodes, setVatCodes] = useState([]);
  //const [vatCode, setVatCode] = useState();
  /*Add Product Data*/
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (!clientid) {
      AlertError(
        "Please select a client"
      );
      return;
    }
    if (!wareObj) {
      AlertError(
        "Please select a warehouse" 
      );
      return;
    }
    let errors = productRows.filter((res) => {
      return (
        res.product_id.length === 0 || res.quantity === 0
      );
    });
    if (errors.length > 0 || productRows.length === 0) {
      AlertError(
        "The Packaging Information section required attention. All fields are required."
      );
      return;
    }
    data.products = productRows;
    data.client_id = clientid['value'];
    data.warehouse = wareObj;
    data.vat_code = 1;
    postData("/products/add", data)
      .then((response) => {
        let resposnseData = response.data;
        if (resposnseData.success === false) {
          AlertError(resposnseData.message);
          return;
        }
        AlertSuccess(resposnseData.message);
        navigate("/products");
      })
      .catch((e) => {
        AlertError(e);
      });
  };
  const removeProductRow = (index) => {
    let newRows = productRows.filter((arrow, key) => key !== index);
    setProductsRows(newRows);
  };

  const addProduct = () => {
    getProduct()
  };
  const updateProductRow = (res, index) => {
    productRows[index]["product_id"] = res ? res : [];
  };
  const getProduct= () => {
    getData("/purchaseproduct/filter").then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        let newAdded = [
          ...productRows,
          { products: responseData.data, product_id: 0, quantity: 0 },
        ];
        setProductsRows(newAdded);
      }
    });
  };
  const getClients= () => {
    getData("/clients/filter").then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        setClients(responseData.data)
      }
    });
  };
  useEffect(() => {
      // getData("/vatcodes/filter").then((response) => {
      //   if (response.status === 200) {
      //     let responseData = response.data;
      //     setVatCodes(responseData.data)
      //   }
      // });
    getData("/products/warehouse").then((response) => {
      if (response.status === 200) {
        let responseData = response.data;
        setWarehouses(responseData.data)
      }
    });
    dispatch(setPageTitle("Add New Product"));
    dispatch(setBreadcrumbs([
      {
          label: 'Dashboard',
          link: '/dashboard',
          active: false, // true,false
          state: '/products/add',
      },{
          label: 'Products',
          link: '/products',
          active: false, // true,false
          state: '/products/add',
      },{
        label: 'Add New Product',
        link: '/products/add',
        active: true, // true,false
        state: '/products/add',
      }
    ]))
    getClients()
  }, [dispatch]);
  return (
    <DefaultLayout>
      <section className="section">
  <div className="row">
    <div className="card">
      <div className="container py-2 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-lg-12 col-xl-12">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-6 col-xl-6 mb-3">
                    <label
                      htmlFor="name"
                      className="col-sm-2 col-form-label"
                    >
                      SKU<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control"
                        id="code"
                        defaultValue=""
                        {...register("code", { required: true, maxLength: 20 })}
                      />
                      {errors?.code && errors.code.type === "required" && (
                        <span className="error">Please enter code</span>
                      )}
                      {errors?.code && errors.code.type === "maxLength" && (
                        <span className="error">Max length exceeded</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 mb-3">
                    <label
                      htmlFor="name"
                      className="col-sm-2 col-form-label"
                    >
                      Description<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                      type="text"
                      className="form-control"
                      id="description"
                      defaultValue=""
                      {...register("description", {
                        required: true,
                        maxLength: 125,
                      })}
                    />
                    {errors?.description &&
                      errors.description.type === "required" && (
                        <span className="error">Please enter description</span>
                      )}
                    {errors?.description &&
                      errors.description.type === "maxLength" && (
                        <span className="error">Max length exceeded</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 mb-3">
                    <label
                      htmlFor="bottle_barcode"
                      className="col-sm-3 col-form-label"
                    >
                     Bottle Barcode<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                      type="text"
                      className="form-control"
                      id="bottle_barcode"
                      defaultValue=""
                      {...register("bottle_barcode", {
                        required: true,
                        maxLength: 13
                      })}
                    />
                    {errors?.bottle_barcode && errors.bottle_barcode.type === "required" && (
                      <span className="error">Please enter Bottle Barcode</span>
                    )}
                    {errors?.bottle_barcode && errors.bottle_barcode.type === "maxLength" && (
                      <span className="error">Max length exceeded</span>
                    )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 mb-3">
                    <label
                      htmlFor="case_barcode"
                      className="col-sm-3 col-form-label"
                    >
                     Case Barcode<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                      type="text"
                      className="form-control"
                      id="case_barcode"
                      defaultValue=""
                      {...register("case_barcode", {
                        required: true,
                        maxLength: 13
                      })}
                    />
                    {errors?.case_barcode && errors.case_barcode.type === "required" && (
                      <span className="error">Please enter case barcode</span>
                    )}
                    {errors?.case_barcode && errors.case_barcode.type === "maxLength" && (
                      <span className="error">Max length exceeded</span>
                    )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 mb-3">
                    <label
                      htmlFor="outer_case_barcode"
                      className="col-sm-4 col-form-label"
                    >
                     Outer Case Barcode
                    </label>
                    <div className="col-sm-10">
                      <input
                      type="text"
                      className="form-control"
                      id="outer_case_barcode"
                      defaultValue=""
                      {...register("outer_case_barcode", {
                        required: false,
                        maxLength: 13
                      })}
                    />
                    {errors?.case_barcode && errors.case_barcode.type === "required" && (
                      <span className="error">Please enter outer case barcode</span>
                    )}
                    {errors?.case_barcode && errors.case_barcode.type === "maxLength" && (
                      <span className="error">Max length exceeded</span>
                    )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 mb-3">
                    <label
                      htmlFor="partner_code"
                      className="col-sm-3 col-form-label"
                    >
                      Client<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                    <SelectOptions options={clients} default={clientid} changeFn={(data) => (data) ? setClientId(data) : setClientId(0)}  isClearable={true} placeholder={'Client'}  />
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 mb-3">
                    <label
                      htmlFor="quantity"
                      className="col-sm-6 col-form-label"
                    >
                    Bottles Quantity<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                      type="number"
                      className="form-control"
                      id="quantity"
                      defaultValue={0}
                      {...register("quantity", {
                        required: true,
                        valueAsNumber: true,
                      })}
                    />
                    {errors?.quantity && errors.quantity.type === "required" && (
                      <span className="error">
                        Please enter valild quantity. It should be a number
                      </span>
                    )}
                    {errors?.quantity && errors.quantity.type === "valueAsNumber" && (
                      <span className="error">Please enter quantity in number</span>
                    )}
                    </div>
                   
                  </div>
                  <div className="col-lg-6 col-xl-6 mb-3">
                    <label
                      htmlFor="quantity"
                      className="col-sm-4 col-form-label"
                    >
                     Bottles Per Pallets  <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                      type="number"
                      className="form-control"
                      id="palletperquantity"
                      defaultValue={0}
                      {...register("palletperquantity", {
                        required: true,
                        valueAsNumber: true
                      })}
                    />
                    {errors?.palletperquantity && errors.palletperquantity.type === "required" && (
                      <span className="error">
                        Please enter valild pallet per quantity. It should be a number
                      </span>
                    )}
                    {errors?.palletperquantity && errors.palletperquantity.type === "valueAsNumber" && (
                      <span className="error">Please enter palletperquantity in number</span>
                    )}
                    </div>
                    <small className="text-danger">*This will used for production plan calculations only</small> 
                  </div>
                  <div className="col-lg-6 col-xl-6 mb-3">
                    <label
                      htmlFor="quantity"
                      className="col-sm-2 col-form-label"
                    >
                     Pallet Type<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                      <input
                      type="text"
                      className="form-control"
                      id="pallet_type"
                      defaultValue={""}
                      {...register("pallet_type", {
                        required: true
                      })}
                    />
                    {errors?.pallet_type && errors.pallet_type.type === "required" && (
                      <span className="error">
                        Please enter pallet type
                      </span>
                    )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-6 mb-3">
                    <label
                      htmlFor="tax_code"
                      className="col-sm-3 col-form-label"
                    >
                     Warehouse<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                    <SelectOptions options={warehouses} default={wareObj} changeFn={(data) => setWareObj(data)}  isClearable={true} placeholder={'VAT Code'}  />
                    </div>
                  </div> 
                  {/* <div className="col-lg-6 col-xl-6 mb-3">
                    <label
                      htmlFor="tax_code"
                      className="col-sm-3 col-form-label"
                    >
                      VAT Code<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                    <SelectOptions options={vatCodes} default={vatCode} changeFn={(data) =>  setVatCode(data)}  isClearable={true} placeholder={'VAT Code'}  />
                    </div>
                  </div> */}
                  <div className="col-lg-6 col-xl-6 mb-3">
                    <label
                      htmlFor="status"
                      className="col-sm-2 col-form-label"
                    >
                      Status<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-10">
                   
                      <select
                      id="status"
                      className="form-control"
                      defaultValue={1}
                      {...register("status", { required: true })}
                    >
                      <option value={1}>Active</option>
                      <option value={2}>In-Active</option>
                    </select>
                    {errors?.status && (
                      <span className="error">Please select a status</span>
                    )}
                    </div>
                  </div>
                  
                  <hr />
                  <div className="col-12 mb-3">
                    <h5 className="card-title">Raw material Information<span className="text-danger">*</span></h5>
                  </div>
                  <div className="row g-3 mb-3">
                  {productRows.length > 0 &&
                    productRows.map((product, index) => (
                      <React.Fragment key={index}>
                        <div className="col-2">
                          <label className="col-form-label">
                            Products<span className="text-danger">*</span>
                          </label>
                          <Select
                            options={productRows[index].products}
                            defaultValue={product.product_id}
                            className="col-12"
                            isSearchable={true}
                            isClearable={true}
                            onChange={(res) => updateProductRow(res, index)}
                          />
                        </div>
                        <div className="col-2">
                          <label className="col-form-label">
                            Quantity<span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            min={1}
                            defaultValue={0}
                            onInput={(res) => {
                              productRows[index]["quantity"] = res.target.value;
                            }}
                          />
                        </div>
                          <div className="col-2">
                            <div className="mt-2-5">
                              <DeleteButton
                                iclass="trash-fill"
                                buttonClass="danger"
                                clickfun={() => {
                                  if (window.confirm("Delete the item?")) {
                                    removeProductRow(index);
                                  }
                                }}
                              />
                            </div>
                          </div>
                      </React.Fragment>
                    ))}
                      </div>
                  <div className="row g-3 mb-3">
                    <div className="col-3">
                      <PrimaryButton
                        title="Add Product"
                        clickfun={addProduct}
                        iclass="plus"
                        buttonClass="primary"
                      />
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary sub-btn"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </DefaultLayout>
  );
};
export default AddSaleProduct;
